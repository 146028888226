<template>
  <div class="w-100">
    <b-row class="mb-5">
      <b-col md="2">
        <b-input-group>
          <date-picker
            placeholder="Từ"
            class="form-control form-control-sm"
            :config="dpConfigs.date"
            v-model="apiParams.fromDate"
          ></date-picker>
        </b-input-group>
      </b-col>
      <b-col md="2">
        <b-input-group>
          <date-picker
            placeholder="Đến"
            class="form-control form-control-sm"
            :config="dpConfigs.date"
            v-model="apiParams.toDate"
          ></date-picker>
        </b-input-group>
      </b-col>
      <b-col md="2">
        <b-form>
          <b-form-select
            v-model="apiParams.status"
            :options="ORDER_STATUS"
            size="sm"
          ></b-form-select>
        </b-form>
      </b-col>
      <b-col md="1">
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          @click="fetchMainData()"
          >Lọc
        </b-button>
      </b-col>
    </b-row>
    <table class="table b-table table-bordered-list table-hover col-md-12">
      <thead>
        <tr>
          <th class="text-center header-table-color" style="width: 2%"></th>
          <th class="text-center header-table-color" style="width: 5%">
            ID
          </th>
          <th class="text-center header-table-color" style="width: 10%">
            Khách hàng
          </th>
          <th class="text-center header-table-color" style="width: 20%">
            Sản phẩm
          </th>
          <th class="text-center header-table-color">Giá</th>
          <th class="text-center header-table-color">
            SL
          </th>
          <th class="text-center">Tổng tiền</th>
          <th class="text-center header-table-color">
            <i
              v-b-tooltip.hover
              title="Thanh toán"
              class="fa fa-money-check-alt icon-color"
            ></i>
          </th>
          <th class="text-center header-table-color" style="width: 15%">
            Ghi chú
          </th>
          <th class="text-center header-table-color">Trạng thái</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(entry, i) in mainList.dataset">
          <template v-for="(orderItem, j) in entry.orderItems">
            <tr
              :key="i + j + orderItem.orderItemId"
              @mouseover="hoverRow"
              @mouseleave="outRow"
              :data="`row_${entry.id}`"
            >
              <td
                v-if="j === 0 || entry.show ? true : false"
                :rowspan="entry.orderItems.length"
                class="tdCenter"
              >
                <div class="mt-3">
                  <i
                    v-if="entry.orderType === 1"
                    class="fas fa-store icon-color text-warning"
                    v-b-tooltip.hover.bottom
                    title="Mua tại quầy"
                  ></i>

                  <i
                    v-else-if="entry.orderType === 2"
                    class="
                              fas
                              fa-shopping-basket
                              icon-color
                              text-danger
                            "
                    v-b-tooltip.hover.bottom
                    title="Đặt trước"
                  ></i>
                  <i
                    v-else-if="entry.orderType === 3"
                    class="fas fa-truck icon-color text-success"
                    v-b-tooltip.hover.bottom
                    title="Tự vận chuyển"
                  ></i>
                  <i
                    v-else-if="entry.orderType === 4"
                    class="fas fa-box icon-color text-primary"
                    v-b-tooltip.hover.bottom
                    title="Hãng vận chuyển (Ahamove, Nhất tín ...)"
                  ></i>
                </div>
              </td>
              <td
                v-if="j === 0 || entry.show ? true : false"
                :rowspan="entry.orderItems.length"
                class="tdCenter"
              >
                <div
                  class="orderCode text-primary font-weight-bold"
                  @click="editItem(entry)"
                >
                  {{ entry.id }}
                </div>
                <p class="text-date-create text-primary">
                  {{ formatDate(entry.createdAt, 'DD/MM/YYYY HH:mm') }}
                </p>
                <p>
                  {{ entry.createdByName }}
                </p>
                <b v-if="entry.storeName" style="font-size: 10px">
                  {{ entry.storeName }}
                </b>
              </td>
              <td
                v-if="j === 0 || entry.show ? true : false"
                :rowspan="entry.orderItems.length"
                class="tdCenter"
              >
                <p
                  class="orderCode"
                  @click="editUser(entry)"
                  style="
                            margin-bottom: 0;
                            font-weight: 550;
                          "
                >
                  {{ entry.customerName }}
                </p>
                <p
                  class="orderCode"
                  @click="editUser(entry)"
                  style="margin-bottom: 0;"
                >
                  {{ entry.customerMobile }}
                </p>
                <div
                  v-if="
                    entry.customerAddress &&
                      entry.customerAddress.trim().length > 0
                  "
                >
                  <b>ĐC:</b>
                  {{ entry.customerAddress }}
                </div>
              </td>

              <td class="align-middle">
                <p class="m-0">
                  <i
                    style="color: rgb(24, 28, 50)"
                    class="fas fa-gift ml-4 icon-color"
                    v-if="orderItem.orderItemType === 2"
                  ></i>
                  <i
                    style="color: rgb(24, 28, 50)"
                    class="fas fa-tags ml-4"
                    v-else-if="orderItem.orderItemType === 3"
                  ></i>
                  {{ orderItem.productName }}
                </p>
                <p
                  v-if="
                    orderItem.imeiCode && orderItem.imeiCode.trim().length > 0
                  "
                >
                  <i class="text-primary" style="font-size: 12px"
                    >IMEI: {{ orderItem.imeiCode }}</i
                  >
                </p>
                <tr
                  v-show="
                    orderItem.productInCombos &&
                      orderItem.productInCombos.length > 0
                  "
                  v-for="(items, index) in orderItem.productInCombos"
                  :key="index"
                  style="border-top: 1px solid gray"
                >
                  <td style="border: none">
                    <i
                      v-b-tooltip.hover
                      title="Sản phẩm"
                      class="fas fa-cube icon-color"
                    ></i>
                  </td>
                  <td style="border: none; width: -webkit-fill-available">
                    {{ items.productName }}({{ items.quantity }})
                  </td>
                </tr>
              </td>

              <td class="text-right align-middle">
                {{ convertPrice(orderItem.productPrice) }}
                <p
                  v-b-tooltip.hover.bottom
                  title="Chiết khấu sản phẩm"
                  v-if="orderItem.discountAmount"
                >
                  <i
                    class="fa fa-arrow-down icon-color"
                    style="font-size: 12px;text-align:center;"
                  ></i
                  >:
                  <b class="text-warning">
                    {{ convertPrice(orderItem.discountAmount) }}</b
                  >
                </p>
              </td>

              <td class="text-center align-middle">
                {{ orderItem.quantity }}
              </td>

              <td
                v-if="j === 0 || entry.show ? true : false"
                :rowspan="entry.orderItems.length"
                class="text-right align-middle"
              >
                <p v-show="entry.customerShipFee > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Phí ship"
                    class="fas fa-shipping-fast icon-color"
                  ></i>
                  : {{ convertPrice(entry.customerShipFee) }}
                </p>
                <p v-show="entry.shipFee > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Phí trả hãng vận chuyển"
                    class="fas fa-hand-holding-usd icon-color"
                  ></i>
                  : {{ convertPrice(entry.shipFee) }}
                </p>
                <p v-show="entry.discountAmount > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Chiết khấu"
                    class="fa fa-arrow-down icon-color"
                  ></i>
                  : {{ convertPrice(entry.discountAmount) }}
                  <span v-if="entry.discountType === 2"> % </span>
                </p>
                <b style="color: #2e7d32">
                  {{ convertPrice(entry.totalAmount) }}</b
                >
              </td>

              <td
                v-if="j === 0 || entry.show ? true : false"
                :rowspan="entry.orderItems.length"
                class="text-right align-middle"
              >
                <p v-show="entry.depositAmount > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Đặt cọc"
                    class="fa fa-money-check-alt icon-color"
                  ></i>
                  : {{ convertPrice(entry.depositAmount) }}
                </p>
                <p v-show="entry.transferAmount > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Đã chuyển khoản"
                    class="fa fa-university icon-color"
                  ></i>
                  : {{ convertPrice(entry.transferAmount) }}
                </p>
                <p v-show="entry.creditAmount > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Tiền quẹt thẻ"
                    class="fa fa-credit-card icon-color"
                  ></i>
                  : {{ convertPrice(entry.creditAmount) }}
                </p>
                <p v-show="entry.installedMoneyAmount > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Trả góp"
                    class="fa fa-cube icon-color"
                  ></i>
                  : {{ convertPrice(entry.installedMoneyAmount) }}
                </p>
                <span
                  v-b-tooltip.hover
                  title="Phải thu khách"
                  style="font-weight: 500"
                  class="border-0 text-success"
                  >{{ convertPrice(entry.totalAmountCustomerPay) }}</span
                >
              </td>

              <td
                v-if="j === 0 || entry.show ? true : false"
                :rowspan="entry.orderItems.length"
                class="wrapword"
              >
                <span v-if="entry.codeShip && entry.codeShip.trim().length > 0">
                  <b>Mã vận đơn:</b> {{ entry.codeShip }}
                </span>
                <p
                  v-if="entry.codeShip && entry.codeShip.trim().length > 0"
                  class="dashed"
                ></p>
                <p v-if="entry.customerNote">
                  {{ entry.customerNote }}
                </p>
                <p
                  v-if="entry.customerNote && entry.saleNote"
                  class="dashed"
                ></p>
                <p v-if="entry.saleNote">{{ entry.saleNote }}</p>
              </td>

              <td
                v-if="j === 0 || entry.show ? true : false"
                :rowspan="entry.orderItems.length"
                class="align-middle text-center"
              >
                <span v-if="entry.cancelStatus" class="d-block">
                  ({{ getStatusCancelName(entry.cancelStatus) }})
                </span>
                <span
                  v-text="getStatusName(entry.status)"
                  style="width: max-content"
                  v-bind:class="{
                    'badge badge-warning text-white': entry.status === 1,
                    'badge badge-light': entry.status === 2,
                    'badge badge-primary text-white': entry.status === 3,
                    'badge badge-warning': entry.status === 4,
                    'badge badge-info': entry.status === 5,
                    'badge badge-success': entry.status === 6,
                    'badge badge-dark':
                      entry.status === 7 || entry.status === 8,
                    'badge badge-danger': entry.status === 9
                  }"
                ></span>
                <div v-if="entry.status === 6 && entry.billNumber" class="mt-2">
                  <span
                    style="color: darkgoldenrod"
                    v-text="entry.billNumber"
                    @click="linkToOrder(entry.billNumber)"
                    class="orderCode"
                  ></span>
                  <div class="mt-4">
                    <p v-if="entry.billState === 'DELETED'">
                      <i
                        class="fa fa-exclamation-triangle text-danger"
                        v-b-tooltip.hover.bottom
                        title="Hoá đơn đã được xoá"
                        aria-hidden="true"
                      ></i>
                    </p>
                    <p v-if="entry.billState === 'NOAMOUNT'">
                      <i
                        class="fa fa-exclamation-triangle  text-warning"
                        v-b-tooltip.hover.bottom
                        title="Hoá đơn không có doanh thu"
                        aria-hidden="true"
                      ></i>
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </template>
        <tr v-show="!onLoadingList">
          <td
            :colspan="4"
            class="
                      align-middle
                      text-right
                      icon-color
                      font-weight-bolder
                    "
          >
            Tổng
          </td>
          <td></td>
          <td></td>
          <td
            class="
                      align-middle
                      text-right
                      icon-color
                      font-weight-bolder
                    "
          >
            {{ sumQuantity }}
          </td>
          <td
            class="
                      align-middle
                      text-right
                      icon-color
                      font-weight-bolder
                    "
          >
            <p class="mb-0">
              <i
                v-b-tooltip.hover
                title="Phí vận chuyển"
                class="fa fa-truck header-table-color"
                style="font-size: inherit"
              ></i>
              {{ convertPrice(sumShipFee) }}
            </p>
            <p class="mb-0">
              <i
                v-b-tooltip.hover
                title="Phí ship báo khách"
                class="fas fa-user header-table-color"
                style="font-size: inherit"
              ></i>
              {{ convertPrice(sumCustomerShipFee) }}
            </p>
          </td>
          <td
            class="
                      align-middle
                      text-right
                      icon-color
                      font-weight-bolder
                    "
          >
            <p class="mb-0">
              <i
                v-b-tooltip.hover
                title="Tổng chuyển khoản"
                class="fas fa-university header-table-color"
                style="font-size: inherit"
              ></i>
              {{ convertPrice(sumTransferAmount) }}
            </p>
            <p class="mb-0">
              <i
                v-b-tooltip.hover
                title="Tổng đặt cọc"
                class="fas fa-user header-table-color"
                style="font-size: inherit"
              ></i>
              {{ convertPrice(sumDepositAmount) }}
            </p>
            <p class="mb-0" v-b-tooltip.hover title="Tổng thu">
              {{ convertPrice(sumTotalAmount) }}
            </p>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <vcl-table
      v-if="onLoadingList"
      :speed="5"
      :animate="true"
      :columns="10"
    ></vcl-table>
    <b-row>
      <b-col lg="3" md="3" sm="12">
        <p class="mt-3 text-dark" style="font-weight: 500">
          Tổng số:
          {{ mainList.total }}
        </p>
      </b-col>
      <b-col lg="9" md="9" sm="12">
        <b-pagination-nav
          class="customPagination"
          v-show="mainList.pages >= 2"
          :link-gen="linkGen"
          v-model="currentPage"
          :number-of-pages="mainList.pages"
          use-router
          @change="fetchMainData(pageInfo)"
          align="right"
          first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
          prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
          next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
          last-class="page-item-last btn btn-icon btn-sm my-1 "
          page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
        >
          <template v-slot:first-text>
            <span>
              <i class="ki ki-bold-double-arrow-back icon-xs"></i>
            </span>
          </template>

          <template v-slot:prev-text>
            <i class="ki ki-bold-arrow-back icon-xs"></i>
          </template>

          <template v-slot:next-text>
            <i class="ki ki-bold-arrow-next icon-xs"></i>
          </template>

          <template v-slot:last-text>
            <span class="text-info">
              <i class="ki ki-bold-double-arrow-next icon-xs"></i>
            </span>
          </template>
        </b-pagination-nav>
      </b-col>
    </b-row>
  </div>
</template>

<style>
.search-col {
  display: flex;
  align-items: center;
}

.first-search-col {
  display: flex;
  align-items: center;
}
.search-label {
  min-width: 90px;
  text-align: right;
  margin-right: 0.5rem !important;
  font-weight: 500;
}
#autosuggest {
  width: 100%;
}
input::-webkit-input-placeholder {
  color: rgb(190, 190, 200);
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  border-radius: 0.28rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.test {
  position: absolute;
  right: 1vw;
}
.checkbox-label {
  display: block;
}
.multiselect {
  min-height: initial;
}
.multiselect__tags {
  display: block;
  padding: 3px 40px 0 8px !important;
  border-radius: 0.28rem;
  border: 1px solid #ced4da !important;
  background: #fff;
  font-size: 12px !important;
  min-height: fit-content !important;
}
.multiselect__element {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__input {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__single {
  font-size: 12px;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 12px !important;
}

.multiselect__input,
.multiselect__single {
  min-height: 10px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.orderCode:hover {
  text-decoration: underline;
  cursor: pointer;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

/* .table th,
.table td {
  text-align: center !important;
  vertical-align: middle;
} */

.itemBorder {
  border: 1px solid black;
}
.tdItemWidth {
  width: 350px;
}
.tdCenter {
  vertical-align: middle;
}
.icon-color {
  color: #464e5f;
}
.header-table-color {
  color: rgb(24, 28, 50);
}
.text-date-create {
  margin-top: 10px;
}
.table-bordered-list th {
  border: 0.5px solid #d2d4d6 !important;
  background-color: #e0e0e0;
}
.table-bordered-list td {
  border: 0.5px solid #d2d4d6;
}
.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}
.dashed {
  border: 1px dashed #464e5f;
  border-style: none none dashed;
  color: #fff;
  background-color: #fff;
}
input {
  border: 1px solid #ced4da;
}
.wrapword {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-word;
  white-space: normal;
}
</style>

<script>
import { VclTable } from 'vue-content-loading';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from './../../utils/apiUrl';
import _ from 'lodash';
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import { formatDate, convertPrice, makeToastFaile } from '../../utils/common';
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle'
  }
});
import { ORDER_STATUS } from '../../utils/constants';
export default {
  props: ['customerId'],
  components: {
    VclTable,
    datePicker
  },
  // mixins: [common],
  data() {
    return {
      ORDER_STATUS,
      mainList: {
        dataset: [],
        total: 0,
        pages: 1
      },
      apiParams: {
        page: 1,
        pageSize: 10,
        fromDate: null,
        toDate: null,
        status: null,
        customerId: null
      },
      onLoadingList: false,
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true
        }
      },
      currentPage: 1,
      sumShipFee: 0,
      sumCustomerShipFee: 0,
      sumTransferAmount: 0,
      sumDepositAmount: 0,
      sumTotalAmount: 0,
      sumQuantity: 0,
      listCancelStatus: [
        { value: '', text: 'Chọn lý do' },
        { value: '1', text: 'Đã mua tại quầy' },
        { value: '2', text: 'Đơn trùng' },
        { value: '3', text: 'Đã mua nơi khác' },
        { value: '4', text: 'Không liên hệ được khách hàng' },
        { value: '5', text: 'Khách suy nghĩ thêm' },
        { value: '6', text: 'Hết hàng' },
        { value: '7', text: 'Lý do khác' }
      ]
    };
  },
  mounted() {
    this.fetchMainData();
  },
  methods: {
    formatDate,
    convertPrice,
    hoverRow: function(evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.addClass('tr-rowspan-hover');
    },
    outRow: function(evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.removeClass('tr-rowspan-hover');
    },
    fetchMainData: function(params) {
      if (!this.customerId) {
        makeToastFaile('Không tìm thấy Id khách hàng');
        return;
      }

      this.getParamFilters();
      // Init request header.
      this.onLoadingList = true;

      ApiService.query(cmdUrl.OrderUrl.gets, {
        params: params ? params : { ...this.apiParams }
      })
        .then(response => {
          const respData = response.data.data;
          this.mainList = respData;
          this.onLoadingList = false;
        })
        .catch(() => {
          this.onLoadingList = false;
        });
    },
    getParamFilters() {
      this.currentPage = this.$route.query.page;
      if (!this.currentPage) {
        this.currentPage = 1;
      }

      this.apiParams.page = this.currentPage;
      this.apiParams.customerId = this.customerId;
    },
    isShowRow(listData, item) {
      return _.findIndex(listData, ['id', item.id]) === 0;
    },
    calculateDiscountPercent(bill) {
      const discount = bill.discountAmount || 0;
      const total = bill.totalAmount || 0;
      if (total !== 0) {
        return parseFloat((discount / total) * 100).toFixed(1);
      }
      return 0;
    },
    sumAmount(key) {
      const outItems = this.mainList.dataset;
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item[key]);
        },
        0
      );
    },
    linkGen(pageNum) {
      return pageNum === 1
        ? `?id=${this.customerId}`
        : `?id=${this.customerId}&page=${pageNum}`;
    },
    getStatusName(id) {
      switch (id) {
        case 1:
          return 'Mới';
        case 2:
          return 'Đang xử lí';
        case 3:
          return 'Đã xác nhận';
        case 4:
          return 'Đã chuẩn bị hàng';
        case 5:
          return 'Đang đóng gói';
        case 6:
          return 'Thành công';
        case 7:
          return 'Khách hủy';
        case 8:
          return 'Hệ thống hủy';
        case 9:
          return 'Đã chuyển hoàn';
        default:
          return '';
      }
    },
    getStatusCancelName(id) {
      const cancelStt = this.listCancelStatus.find(x => x.value == id);
      if (cancelStt) return cancelStt.text;
      return 'Chưa có trạng thái lý do';
    }
  },
  computed: {
    sumQty() {
      let sum = 0;
      _.map(this.mainList.dataset, item => {
        const tmpSum = _.sumBy(item.billItems, 'quantity');
        sum += tmpSum;
      });
      return sum;
    }
  }
};
</script>
