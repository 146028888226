<template>
  <div class="w-100">
    <b-row class="mb-5">
      <b-col md="2">
        <b-input-group>
          <date-picker
            placeholder="Từ"
            class="form-control form-control-sm"
            :config="dpConfigs.date"
            v-model="apiParams.fromDate"
          ></date-picker>
        </b-input-group>
      </b-col>
      <b-col md="2">
        <b-input-group>
          <date-picker
            placeholder="Đến"
            class="form-control form-control-sm"
            :config="dpConfigs.date"
            v-model="apiParams.toDate"
          ></date-picker>
        </b-input-group>
      </b-col>
      <b-col md="2">
        <b-form>
          <b-form-select
            v-model="apiParams.type"
            :options="BILL_TYPE"
            class="select-style"
            size="sm"
          ></b-form-select>
        </b-form>
      </b-col>
      <b-col md="1">
        <b-button
          style="width: 70px"
          variant="primary"
          size="sm"
          @click="fetchMainData()"
          >Lọc
        </b-button>
      </b-col>
    </b-row>
    <b-table-simple class="table-bordered table-hover tbody-hover col-md-12">
      <b-thead>
        <b-tr>
          <b-th class="text-center tdDate">Ngày</b-th>
          <b-th class="text-center tdCode">ID</b-th>
          <b-th class="text-center tdStore">Cửa hàng</b-th>
          <b-th class="text-center tdCustomer">Khách hàng</b-th>
          <b-th class="text-center tdProductName">Sản phẩm</b-th>
          <b-th class="text-center tdProductPrice">Giá</b-th>
          <b-th class="text-center tdQuantity">SL</b-th>
          <b-th class="text-center tdDiscountItem">C.Khấu</b-th>
          <b-th class="text-center tdTotalAmount">Tổng tiền</b-th>
          <b-th class="text-center tdTotalPayment">Thanh toán</b-th>
          <b-th class="text-center tdNote">Ghi chú</b-th>
          <b-th class="text-center "></b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-for="bill in mainList.dataset" :key="bill.id">
        <b-tr v-for="(item, index) in bill.billItems" :key="index">
          <b-td
            :rowspan="bill.billItems.length + 1"
            class="tdDate"
            v-show="isShowRow(bill.billItems, item)"
          >
            {{ formatDate(bill.createdAt) }}
            <p class="mb-0" v-if="bill.cashierName">
              {{ bill.cashierName }}
            </p>
            <p v-if="bill.saleName">
              <b class="mb-0 ">
                NVBH:
                <br />
              </b>
              <span>{{ bill.saleName }}</span>
            </p>
          </b-td>
          <b-td
            :rowspan="bill.billItems.length + 1"
            class="text-center tdCode"
            v-show="isShowRow(bill.billItems, item)"
          >
            <div>
              <router-link
                :to="{
                  path: '/bills/update-bill',
                  query: { id: bill.billNumber }
                }"
                target="_blank"
                >{{ bill.billNumber }}
              </router-link>
            </div>
            <div class="text-center">
              <i
                v-if="bill.type === 4"
                class="fas fa-shipping-fast text-success"
                v-b-tooltip.hover
                title="Hóa đơn giao hàng từ đơn hàng"
              ></i>
              <i
                v-if="bill.type === 1 && bill.orderId"
                class="fas fa-shopping-basket text-success"
                v-b-tooltip.hover
                title="Hóa đơn từ đơn hàng"
              ></i>
              <i
                v-if="bill.type === 1 && !bill.orderId"
                class="fas fa-shopping-bag text-infor"
                v-b-tooltip.hover
                title="Hóa đơn bán lẻ"
              ></i>
              <i
                v-if="bill.type === 2"
                class="fas fa-shopping-cart text-warning"
                v-b-tooltip.hover
                title="Hóa đơn bán buôn"
              ></i>
              <i
                v-if="bill.type === 3"
                class="fas fa-undo-alt text-dark"
                v-b-tooltip.hover
                title="Hóa đơn trả hàng"
              ></i>
              <div
                v-if="bill.orderId"
                class="orderCode"
                @click="redirectToOrder(bill.orderId)"
              >
                <span style="color: darkgoldenrod">{{ bill.orderId }}</span>
              </div>
            </div>
          </b-td>
          <b-td
            :rowspan="bill.billItems.length + 1"
            class="text-left tdStore"
            v-show="isShowRow(bill.billItems, item)"
          >
            {{ bill.storeName }}
            <br />
            <span v-if="bill.companyName" style="font-size: 10px"
              >( {{ bill.companyName }} )</span
            >
          </b-td>
          <b-td
            :rowspan="bill.billItems.length + 1"
            class="text-left tdCenter tdCustomer"
            v-show="isShowRow(bill.billItems, item)"
          >
            <b> {{ bill.customerName }}</b>
            <p>{{ bill.customerMobile }}</p>
          </b-td>
          <b-td class="text-left tdProductName">
            <div v-if="item.productType !== 3">
              <div v-if="item.productType === 2" class="font-size-gift">
                <i
                  style="color: #2e7d32"
                  class="fas fa-gift ml-4 mr-1"
                  title="Quà tặng"
                ></i>
                {{ item.productName }}
              </div>
              <div v-else-if="item.productType === 3" class="font-size-attach">
                <i
                  style="color: #37474f"
                  class="fas fa-tags ml-4 mr-1"
                  title="Bán kèm"
                ></i>
                {{ item.productName }}
              </div>
              <div v-else>
                {{ item.productName }}
              </div>
              <p class="text-imei-no" v-if="item.imeiCode.trim() !== ''">
                IMEI: {{ item.imeiCode }}
              </p>
            </div>
            <div v-else>
              <div
                class="table-responsive"
                v-show="
                  item.comboItems !== undefined && item.comboItems.length > 0
                "
              >
                <table>
                  <tr>
                    <td style="border: none; padding-left: 0">
                      <p class="m-0">
                        {{ item.productName }}
                      </p>
                    </td>
                  </tr>
                  <tr
                    v-for="(items, i) in item.comboItems"
                    :key="i"
                    style="border-top: 1px solid gray"
                  >
                    <td
                      style="
                                border: none;
                                width: -webkit-fill-available;
                              "
                    >
                      <i style="color: rgb(24, 28, 50)" class="fas fa-cube"></i>
                      {{ items.productName }}
                      <i style="font-size: unset; color: unset"
                        >({{ convertPrice(items.sellingPrice) }})</i
                      >
                    </td>
                    <td style="border: none; font-size: 13px">
                      {{ items.quantity }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-td>
          <b-td class="text-right  tdProductPrice">
            {{ convertPrice(item.productPrice) }}
          </b-td>
          <b-td class="text-right tdCenter tdQuantity">{{
            item.quantity
          }}</b-td>
          <b-td class="text-right tdDiscountItem">
            <p v-if="item.discountType === 1">
              {{ convertPrice(item.discountAmount) }}
            </p>
            <p v-if="item.discountType === 2 && item.discountAmount > 0">
              {{ convertPrice(item.discountAmount) }}%
              <br />
              <span style="color: #ffa800; font-style: italic">
                {{ convertPrice(item.discountPercentAmount) }}</span
              >
            </p>
            <p v-if="item.discountType === 2 && item.discountAmount === 0">
              {{ convertPrice(item.discount) }}
            </p>
          </b-td>
          <b-td
            :rowspan="bill.billItems.length + 1"
            class="text-right tdCenter"
            v-show="isShowRow(bill.billItems, item)"
            style="width: 10% ;color: #3c763d; font-weight: 700"
          >
            <p v-show="bill.discountAmount" class="border-0">
              <i
                v-b-tooltip.hover
                title="Chiết khấu"
                class="fa fa-arrow-down icon-color"
              ></i>
              :
              {{
                bill.discountType === 2
                  ? bill.discountAmount + '%'
                  : convertPrice(bill.discountAmount)
              }}
            </p>
            <span>
              {{ convertPrice(bill.totalAmount) }}
            </span>
            <p v-show="bill.otherFrees > 0" class="border-0">
              <i
                v-b-tooltip.hover
                title="Phí quẹt thẻ thu thêm khách"
                class="fad fa-money-bill-alt"
              ></i>
              : <b>{{ convertPrice(bill.otherFees) }}</b>
            </p>
          </b-td>
          <b-td
            :rowspan="bill.billItems.length + 1"
            class="text-right tdCenter"
            v-show="isShowRow(bill.billItems, item)"
            style="width: 10%"
          >
            <p v-show="bill.pointToMoney > 0" class="border-0">
              <i
                v-b-tooltip.hover
                title="Tiêu điểm"
                class="fas fa-adjust icon-color"
              ></i>
              : <b>{{ convertPrice(bill.pointToMoney) }}</b>
            </p>
            <p v-show="bill.transferAmount > 0" class="border-0">
              <i
                v-b-tooltip.hover
                title="Đã chuyển khoản"
                class="fa fa-university icon-color"
              ></i>
              : <b>{{ convertPrice(bill.transferAmount) }}</b>
            </p>
            <p v-show="bill.creditAmount > 0" class="border-0">
              <i
                v-b-tooltip.hover
                title="Tiền quẹt thẻ"
                class="fa fa-credit-card icon-color"
              ></i>
              : <b>{{ convertPrice(bill.creditAmount) }}</b>
            </p>
            <p v-show="bill.cashAmount > 0" class="border-0">
              <i
                v-b-tooltip.hover
                title="Tiền mặt"
                class="fas fa-money-bill-alt icon-color"
              ></i>
              : <b>{{ convertPrice(bill.cashAmount) }}</b>
            </p>
            <p v-show="bill.installedMoneyAmount > 0" class="border-0">
              <i
                v-b-tooltip.hover
                title="Trả góp"
                class="fa fa-cube icon-color"
              ></i>
              : <b>{{ convertPrice(bill.installedMoneyAmount) }}</b>
            </p>

            <b v-if="bill.extraAmount < 0" class="d-block text-primary mb-2">
              Tiền thừa:
              <span class="d-block ">{{
                convertPrice(bill.extraAmount * -1)
              }}</span>
            </b>
            <b
              v-if="bill.extraAmount > 0"
              class="d-block mb-2"
              style="color: #e65b5b"
            >
              Còn thiếu:
              <span class="d-block"> {{ convertPrice(bill.extraAmount) }}</span>
            </b>
            <p v-show="bill.leftAmount > 0">
              <b>
                Công nợ:
                <span
                  class="d-block"
                  v-text="convertPrice(bill.leftAmount)"
                ></span
              ></b>
            </p>
          </b-td>

          <b-td
            :rowspan="bill.billItems.length + 1"
            class="text-left tdCenter"
            v-show="isShowRow(bill.billItems, item)"
            style="width: 10%"
          >
            {{ bill.saleNote }}
          </b-td>
          <b-td
            :rowspan="bill.billItems.length + 1"
            class="text-center"
            v-show="isShowRow(bill.billItems, item)"
            style="width: 5%"
          >
            <b-dropdown size="sm" no-caret right lazy>
              <template slot="button-content">
                <i style="font-size: 1rem" class="flaticon2-settings pr-0"></i>
              </template>

              <b-dropdown-item
                @click="returnBill(bill)"
                v-if="bill.canReturnBill"
              >
                <span style="color: #3f4254; font-size: 12px">
                  <i style="font-size: 1rem" class="flaticon2-reply-1"></i>
                  &nbsp; Trả hàng
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-else
                @click="
                  makeToastFaile('Không thể trả hàng quá số ngày qui định')
                "
              >
                <span style="color: #3f4254; font-size: 12px">
                  <i style="font-size: 1rem" class="flaticon2-reply-1"></i>
                  &nbsp; Trả hàng
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <tr>
          <td colspan="5" class="text-right ">
            <b>Tổng</b>
          </td>
          <td class="text-right ">
            <b>{{ sumQty }}</b>
          </td>
          <td class="text-right ">
            <b>{{ convertPrice(sumAmount('discountAmount')) }}</b>
          </td>
          <td class="text-right ">
            <b>
              {{
                convertPrice(
                  sumAmount('discountAmount') + sumAmount('totalAmount')
                )
              }}
            </b>
          </td>
          <td class="text-right ">
            <b>
              {{ convertPrice(sumAmount('totalAmount')) }}
            </b>
          </td>
          <td></td>
          <td></td>
        </tr>
      </b-tfoot>
    </b-table-simple>
    <vcl-table v-if="onLoadingList" :speed="5" :animate="true" :columns="11" />
    <b-row>
      <b-col lg="3" md="3" sm="12">
        <p class="mt-3 text-dark" style="font-weight: 500">
          Tổng số:
          {{ mainList.total }}
        </p>
      </b-col>
      <b-col lg="9" md="9" sm="12">
        <b-pagination-nav
          class="customPagination"
          v-show="mainList.pages >= 2"
          :link-gen="linkGen"
          v-model="currentPage"
          :number-of-pages="mainList.pages"
          use-router
          @change="fetchMainData(pageInfo)"
          align="right"
          first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
          prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
          next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
          last-class="page-item-last btn btn-icon btn-sm my-1 "
          page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
        >
          <template v-slot:first-text>
            <span>
              <i class="ki ki-bold-double-arrow-back icon-xs"></i>
            </span>
          </template>

          <template v-slot:prev-text>
            <i class="ki ki-bold-arrow-back icon-xs"></i>
          </template>

          <template v-slot:next-text>
            <i class="ki ki-bold-arrow-next icon-xs"></i>
          </template>

          <template v-slot:last-text>
            <span class="text-info">
              <i class="ki ki-bold-double-arrow-next icon-xs"></i>
            </span>
          </template>
        </b-pagination-nav>
      </b-col>
    </b-row>
  </div>
</template>

<style>
.break-line {
  white-space: break-spaces;
}

.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}
.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}
.table tbody + tbody {
  border-top: unset !important;
}
.text-imei-no {
  font-size: 12px;
  font-style: italic;
  color: dodgerblue;
}
.tdDate {
  width: 5%;
  font-size: 11px;
}
.tdCode {
  width: 5%;
}
.tdStore {
  width: 5%;
}
.tdCustomer {
  width: 7%;
}
.tdProductName {
  width: 15%;
}
.tdProductPrice {
  width: 8%;
}
.tdQuantity {
  width: 3%;
}
.tdDiscountItem {
  width: 5%;
}
.tdTotalAmount {
  width: 8%;
}
.tdTotalPayment {
  width: 10%;
  font-weight: 700;
}
.tdNote {
  width: 19%;
}
.tdAction {
  width: 5%;
}
.wrapword {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}
.table th,
.table td {
  vertical-align: middle;
}
.tdCenter {
  vertical-align: left !important;
}
.tdTextAlign {
  vertical-align: middle;
}
.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
}
.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}
.font-size-gift {
  font-size: 11px;
}
.font-size-attach {
  font-size: 11px;
}
.orderCode:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>

<script>
import { VclTable } from 'vue-content-loading';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from './../../utils/apiUrl';
import _ from 'lodash';
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import { formatDate, convertPrice, makeToastFaile } from '../../utils/common';
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle'
  }
});
import { BILL_TYPE } from '../../utils/constants';
export default {
  props: ['customerId'],
  components: {
    VclTable,
    datePicker
  },
  // mixins: [common],
  data() {
    return {
      mainList: {
        dataset: [],
        total: 0,
        pages: 1
      },
      apiParams: {
        page: 1,
        pageSize: 10,
        fromDate: null,
        toDate: null,
        type: null
      },
      onLoadingList: false,
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true
        }
      },
      currentPage: 1,
      BILL_TYPE
    };
  },
  mounted() {
    this.fetchMainData();
  },
  methods: {
    formatDate,
    convertPrice,
    makeToastFaile,
    fetchMainData: function(params) {
      this.getParamFilters();

      this.onLoadingList = true;

      ApiService.query(
        cmdUrl.BillUrl.byCustomer.replace('{id}', this.customerId),
        { params: params ? params : { ...this.apiParams } }
      )
        .then(response => {
          const respData = response.data.data;
          console.log(respData);
          this.mainList = respData;
          this.onLoadingList = false;
        })
        .catch(() => {
          this.onLoadingList = false;
        });
    },
    getParamFilters() {
      this.currentPage = this.$route.query.page;
      if (this.currentPage === undefined) {
        this.currentPage = 1;
      }
      this.customerId =
        this.$route.query.phoneNo !== undefined
          ? this.$route.query.phoneNo
          : this.$route.query.id;

      if (!this.currentPage) {
        this.currentPage = 1;
      }

      this.apiParams.page = this.currentPage;
    },
    isShowRow: function(listData, item) {
      return _.findIndex(listData, ['id', item.id]) === 0;
    },
    calculateDiscountPercent: function(bill) {
      const discount = bill.discountAmount || 0;
      const total = bill.totalAmount || 0;
      if (total !== 0) {
        return parseFloat((discount / total) * 100).toFixed(1);
      }
      return 0;
    },
    sumAmount(key) {
      const outItems = this.mainList.dataset;
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item[key]);
        },
        0
      );
    },
    linkGen: function(pageNum) {
      return pageNum === 1
        ? `?id=${this.customerId}`
        : `?id=${this.customerId}&page=${pageNum}`;
    },
    redirectToOrder: function(orderId) {
      const { href } = this.$router.resolve({
        name: 'edit-order',
        params: { id: orderId }
      });
      window.open(href, '_blank');
    },
    returnBill: function(item) {
      this.$router.push({
        name: 'add-return-bill',
        query: { id: item.billNumber }
      });
    }
  },
  computed: {
    sumQty: function() {
      let sum = 0;
      _.map(this.mainList.dataset, item => {
        const tmpSum = _.sumBy(item.billItems, 'quantity');
        sum += tmpSum;
      });
      return sum;
    }
  }
};
</script>
